/* You can add global styles to this file, and also import other style files */
:root{
    --primary-color: #C8A86B;
    --secundary-color: #2d2d2d;
}

html, body { height: 100%; }

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.dialog-process .mat-dialog-container{
    padding: 0px;
    overflow: hidden;
}

.mat-primary{
    color: black;
}

.bg-919191{
    background-color: #919191;
    color: #ffffff;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-form-field-appearance-outline .mat-form-field-outline{
    background-color: #ebebeb;
    color: #ebebeb;
    border: none
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{
    color: black;
    border: none;
}

.mat-focused{
    color: black;
}

.mat-form-field.mat-focused .mat-form-field-label{
    color: var(--primary-color);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){
    color: var(--primary-color);
}

.mat-form-field.mat-focused .mat-form-field-ripple{
    background-color: var(--primary-color);
}

.dialog-process .mat-dialog-container{
    width: 65vw; 
}

.mat-chip.mat-standard-chip {
    background-color: #C4C4C4;
    color: white;
    font-size: 1em;
}

.mat-chip.mat-standard-chip .mat-chip-remove {
    color: rgba(255,255,255,1);
    opacity: 1;
}

.mat-menu-content:not(:empty){
    padding: 0.75rem!important;
}

.btn-view{
    cursor: pointer;
}

.box-info{
    padding: 0.75em;
}

.btn-save {
    cursor: pointer;
    background-color: #C8A86B;  
}

.btn-save:hover {
    background-color: #978053;  
}

.btn-cancel {
    cursor: pointer;
    background-color: #2D2D2D;
}

.btn-cancel:hover {
    background-color: #4b4b4b;  
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}
@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}
@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
